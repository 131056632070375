import './App.css';
import { Route, Routes } from 'react-router-dom'
import { ContextProvider } from './Context';
import Memorial from './Components/Memorial/Memorial';
import Home from './Components/Home/Home';

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/:name" element={<Memorial/>} />
        </Routes>
      </ContextProvider>
    </div>
  );
}

export default App;
