import React from "react";
import Bell from "../../Assets/bell .jpeg.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate()
  return (
    <div className="pt-[30px]">
    
      <hr className="pb-[10px]"/>
      <span className="text-[#0D0E13] text-[50px] md:text-[69px] font-medium leading-[140%] "> THANKS</span>
      <img onClick={()=>navigate('/')} className="w-[130px] object-cover md:w-[260px] mx-[auto] mt-[30px]" src={Bell} alt="" />
      <span onClick={()=>navigate('/')} className="cursor-pointer text-[#0D0E13] text-[21px] font-medium leading-[140%] "> www.membells.com</span>
    </div>
  );
}

export default Footer;
