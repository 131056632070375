
// import { authentication } from "../store/firebase-config";
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
const Context = createContext();
export const ContextProvider = ({ children }) => {

  const [imgOFPop, setImgOFPop] = useState()

  const galleryImg = async (name) => {
    try {
      const setApi = await axios.get(
        `/${name}/memorial.json`
      );
      setImgOFPop(setApi.data);

    } catch (err) {
      console.log(err.message);
      setImgOFPop("");

    }
  };

  return (
    <Context.Provider value={{
      galleryImg,
      imgOFPop,
      setImgOFPop
    }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
