import React from "react";
import "../Memorial/Memorial.css";
import Bell from "../../Assets/bell .jpeg.jpg";
import { useParams, useNavigate, Link } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  return (
    <div className="w-[100%] bg-[white]">
      
      <div className="ceneter">
      <div
          onClick={() => navigate(`sajilsalim`)}
          className="flex justify-center items-center cursor-pointer gap-[8px] md:gap-[16px] flex-row "
        >
          <img
            className="rounded-full w-[150px] h-[150px] object-cover"
            src="/sajilsalim/Jineesh/IMG-20230406-WA0074.jpg"
            alt=""
          />
          <p className="text-[#0D0E13] text-[21px] font-medium leading-[140%] ">
            Sajil Salim
          </p>
        </div>
        <img
          className="w-[130px] object-cover md:w-[260px] mx-[auto] mt-[30px]"
          src={Bell}
          alt=""
        />
        <div className=" flex justify-center items-center gap-[10px]">
          <img
            className="w-[32px] object-cover"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
            alt=""
          />
          <a
            className="text-[#0D0E13] text-[21px] font-medium leading-[140%]"
            href="https://wa.me/9567183183?text=Hello%20Membells!"
          >
            9567183183
          </a>
        </div>
        <span className="text-[#0D0E13] text-[21px] font-medium leading-[140%] ">
          {" "}
          www.membells.com
        </span>
     
      </div>
    </div>
  );
}

export default Home;
