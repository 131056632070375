import styles from "../../Components/Reusable/PopUpModel.module.css";
import Right from "../../Assets/Rightreal.svg";
import Left from "../../Assets/right.svg";
import close from "../../Assets/close.svg";
import ReactPlayer from 'react-player'


function PopUp({ indexNumber, popnowImgOrVideo, setpopnow, handleNext, awsBaseURL, handlePrevious }) {
    console.log(popnowImgOrVideo);
    return (
        <div className={styles.popup}>
            <img className={styles.close} onClick={() => setpopnow(false)} src={close} alt="" />
            <div className={styles.imgmodel}>
                {/* {popnowImgOrVideo?.fileType === 'video' && <ReactPlayer
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        playing={true}
                        controls={true}
                        // muted={true}
                        className={styles.videoplayback}
                        width="100%"
                        url={popnowImgOrVideo?.fileUrl                        }

                    />} */}
                {popnowImgOrVideo?.fileType === "image"  && <img className={styles.theimg} src={popnowImgOrVideo?.fileUrl} alt="" />}
                {/* <img className={styles.theimg} src="https://images.unsplash.com/photo-1680549645205-7330eab343d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" alt="" /> */}
            </div>
            <img className={styles.previous} onClick={() => handleNext(indexNumber)} src={Right} alt="" />
            <img className={styles.next} onClick={() => handlePrevious(indexNumber)} src={Left} alt="" />
        </div>
    );
}

export default PopUp;