import React, { useState, useEffect, useRef, useContext } from "react";
import "./Memorial.css";

function Navbar({ data }) {
  return (
    <div className="navo gap-[10px]">
      <img
        className="w-[32px] h-[32px] object-cover"
        src={data?.[0]?.logo}
        alt=""
      />
      <p className="theNavTxt">{data?.[0]?.headerTitle}</p>
    </div>
  );
}

export default Navbar;
