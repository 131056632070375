import React, { useState, useEffect, useRef, useContext } from "react";
import "./Memorial.css";
import Context from "../../Context";
import Gallery from "../Gallery";
import { Link, useParams, useNavigate } from "react-router-dom";
import PopUp from "../Reusable/PopUpModel";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Memorial() {
  const { galleryImg, imgOFPop, setImgOFPop } = useContext(Context);
  const {name} = useParams()
  useEffect(() => {
    galleryImg(name);
  }, []);
  console.log(imgOFPop?.[0]);
  return (
    <div className="w-[100%] bg-[white]">
      <Navbar data={imgOFPop} />
      <div className="mx-[auto] max-w-[1440px]">
        <div className="h-[508px]">
          <img
            className="h-[100%] w-[100%]  pt-[30px] object-cover"
            src={imgOFPop?.[0]?.coverPicture}
            alt=""
          />
        </div>

        <div className="md:px-[100px] mt-[24px] md:mt-[56px]">
          <div className="p-[20px] mx-[auto]">
            <p className="text-[#0D0E13] text-[21px] font-medium leading-[140%] text-center">
              Moments Shared by Friends & Families
            </p>
            <p className="mt-[12px] text-[#0D0E13] text-[16px] opacity-[0.6] max-w-[353px] font-medium leading-[140%] mx-[auto]">
              Dear Saji, we will miss you in many occasions, but you will always
              live in our memories and the sweet moments we shared. Lot of love
              and prayers for your soul journey.
            </p>
          </div>
          {imgOFPop?.[0]?.moments?.map((obj, i) => {
            return (
              <div>
                <div className="flex justify-start items-center gap-[10px] mb-[20px] mt-[60px]">
                  <img
                    className="w-[32px] rounded-full h-[32px] object-cover"
                    src={obj?.userImage}
                    alt=""
                  />
                  <p className="text-[#0D0E13] text-[16px] font-medium leading-[13px] text-left">
                    {obj?.userName}
                  </p>
                </div>
                <Gallery data={obj?.media} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Memorial;
