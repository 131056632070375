import "./Memorial/Memorial.css";
import { useState } from "react";
import PopUpModel from './Reusable/PopUpModel'
function Gallery({ data }) {
  const awsUrl = "https://memorail.s3.ap-south-1.amazonaws.com";
  const [popnowImgOrVideo, setpopnowImgOrVideo] = useState("");
  const [indexNumber, setindexNumber] = useState();
  const [popnow, setpopnow] = useState(false);
  const handlePrevious = (e) => {
    setpopnowImgOrVideo(
      data[indexNumber - 1]
    );
    setindexNumber(indexNumber - 1);
    if (indexNumber === 0) {
      setpopnowImgOrVideo(
        data &&
        data[data?.length - 1]
      );
      setindexNumber(data.length - 1);
    }
  };

  const handleNext = (e) => {
    setpopnowImgOrVideo(
      data[indexNumber + 1]
    );
    setindexNumber(indexNumber + 1);
    if (indexNumber === data.length - 1) {
      setindexNumber(0);
      setpopnowImgOrVideo(data[0]);
    }
  };

  return (
    <div className="">
      <div className="galrry">
        {data?.map((obj, index) => (
          <div>
            {obj?.fileType === "image" && (
              <img
                onClick={() => {
                  setpopnow(true);
                  setpopnowImgOrVideo(obj);
                  setindexNumber(index);
                }}
                src={obj?.fileUrl}
                alt=""
                className="gallerypHOTOS"
              />
            )}
          </div>
        ))}
   
      </div>
      {popnow && (
        <PopUpModel
          handlePrevious={handlePrevious}
          indexNumber={indexNumber}
          handleNext={handleNext}
          setpopnow={setpopnow}
          popnowImgOrVideo={popnowImgOrVideo}
        />
      )}
    </div>
  );
}

export default Gallery;
